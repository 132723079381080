import { Controller } from "stimulus";
import Rails from '@rails/ujs';
import $ from 'jquery';

export default class extends Controller {
  static targets = [ "notification" ]

  getNotifications(event) {
    let pg = 1;
    pg = event.currentTarget.dataset.pg;

    if ($("#pagination_button").hasClass("loading") == false) {
      $("#pagination_button").addClass("loading");
      $.get( "/en/notifications", {
        pg: pg,
        pagination: true,
      })
      .done(function( data ) {
        console.log(data);
        if (data.length > 1) {
          $("#read-notifications").append(data);
          $("#pagination_button").attr("data-pg", parseInt(pg) + 1);
        } else {
          $("#pagination_button").remove();
        }
        $("#pagination_button.loading").removeClass("loading");
      });
      
    }
  }

  markRead(event) {
    let _this = this;
    let id  = event.currentTarget.dataset.id;
    let read = event.currentTarget.classList.contains('read');

    if (id != null && read == false) {
      fetch("/en/notifications/" + id,
        {
          method: "put",
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken()
          },
          credentials: 'same-origin'
        }

      ).then(data => {
        _this.reloadNotification(id);
      });
        

    }

  }

  reloadNotification(id) {
    let _this = this;

    fetch("/en/notifications/" + id)
    .then(data => {return data.text();})
    .then(html => {
      if (html.length > 0) {
        _this.notificationTarget.outerHTML = html;
      }
    });
  }

  

  markAllRead(event) {
    $(".notification-card:not(.read)").each(function(index) {
      $(this)[0].click();
    });
  }
  
}