import { Controller } from "stimulus";
import { MDCDialog } from '@material/dialog';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "openDialogButton", "dialog"]

  initialize() {
    let _this = this;
    this.dialogTargets.forEach((dialogEl) => {
      let dialog = null;

      if (dialogEl.MDCDialog === undefined) {
        dialog = new MDCDialog(dialogEl);

        let submitButton = dialogEl.querySelector("#dialog-submit-button");
        let fileUploadButton = dialogEl.querySelector('#file-upload-btn'); 
        let submitted = false; //To prevent double click.

        if (submitButton != null) {
          let reload = submitButton.dataset.reload;

          submitButton.addEventListener("click", function(event) {
            let form = dialogEl.querySelector('.mdc-dialog__content form.dialog-submit-form');

            if (form !== null && form.reportValidity() && !submitted) {
              submitted = true;
              let disabled_label = submitButton.dataset.disabledlabel;
              if (disabled_label != null && disabled_label.length > 0)
                submitButton.querySelector(".mdc-button__label").innerHTML = disabled_label;
              submitButton.disabled = true;

              if (form.dataset.remote && form.dataset.remote !== false) { 
                Rails.fire(form, 'submit');

                form.addEventListener("ajax:success", function (event) { 
                  const [_data, _status, xhr] = event.detail;

                  let response_html = xhr.responseText;

                  if (form.dataset.replaceId != null)
                    document.getElementById(form.dataset.replaceId).innerHTML = response_html;

                  dialog.close();
                });


                form.addEventListener("ajax:error", function(event) {
                  console.log("Error: ", error);
                  submitted = false;
                }); 


              } else { 
                form.submit() 
              }
            }

            if (reload == 'true')
              location.reload();

          });
        }

        if (fileUploadButton != null) {
          fileUploadButton.addEventListener('click', function(event) {
            let fileInput = dialogEl.querySelector('input[type=file]');
            fileInput.click();
          });
        }
        
        openDialog(dialog);
      }
    });


    this.openDialogButtonTargets.forEach((btn) => {

      btn.addEventListener("ajax:before", function(event) {
        //event.preventDefault();

        if (btn.dataset.process == "true") {
          event.preventDefault();
        } else {
          btn.dataset.process = "true";
          setTimeout(function() {
            btn.dataset.process = "false";
          }, 2000)
        }

      });

      btn.addEventListener("ajax:success", function(event) {
        const [_data, _status, xhr] = event.detail;
        let dialog_html = xhr.responseText;
        const div = document.createElement('div');
        div.innerHTML = dialog_html;
        const dialogEl = div.firstElementChild;

        if (btn.dataset.rtl == "true") {
          dialogEl.classList.add("rtl");
          dialogEl.setAttribute("dir", "rtl")
        }

        document.body.appendChild(dialogEl);

      });

      btn.addEventListener("ajax:error", function(xhr, status, error) {
        console.log("Error: ", error);
      }); 

    });

    function openDialog(dialog) {
      if (dialog.root.querySelectorAll(".dialog-submit-form").length > 0) {
        let form_changed = false;

        dialog.listen('click', function(event) {
          if (form_changed == true && event.target != undefined && (event.target.classList.contains("mdc-dialog__scrim") || event.target.classList.contains("mdc-dialog__close"))) {
              
            let confirmationDialogTemplate = document.querySelector("#close-confirmation-dialog-template");
            let confirmationDialogEl = null;
            let confirmationDialog = null;

            const div = document.createElement('div');
            div.innerHTML = confirmationDialogTemplate.innerHTML;

            confirmationDialogEl = div.firstElementChild;

            if (dialog.root.getAttribute("dir") == "rtl") {
              confirmationDialogEl.classList.add("rtl");
              confirmationDialogEl.setAttribute("dir", "rtl")
            }

            document.body.appendChild(confirmationDialogEl);

            confirmationDialog = new MDCDialog(confirmationDialogEl);

            confirmationDialog.root.querySelector("#confirmation-save-btn").addEventListener("click", function () {
              confirmationDialog.close();
              let submitButton = dialog.root.querySelector("#dialog-submit-button");
              if (submitButton != undefined)
                submitButton.click();
            });

            confirmationDialog.root.querySelector("#confirmation-close-btn").addEventListener("click", function () {
              confirmationDialog.close();
              dialog.close();
            });

            confirmationDialog.open();
          }
        });

        dialog.root.querySelectorAll("form").forEach(function (form) {
          
          form.addEventListener("input", function () {
            form_changed = true;
            preventDialogClosing(dialog);
          });

          form.querySelectorAll(".mutation_observer").forEach(function (element) {

            var observer = new MutationObserver(function(mutations) {
              mutations.forEach(function(mutation) {
                form_changed = true;
                preventDialogClosing(dialog);
              });    
            });

            var config = { attributes: false, childList: true, characterData: true };
            observer.observe(element, config);
          });
        });
      }

      dialog.listen('MDCDialog:opened', () => {
        window.mdcAutoInit();
        _this.initFlatpickr();
        
        let autofocusInputs = dialog.root.querySelectorAll("textarea[autofocus='autofocus'], input[autofocus='autofocus']");
        if (autofocusInputs.length > 0) {
          autofocusInputs[0].focus();
        }
      });

      dialog.listen('MDCDialog:closing', function() {
      });

      dialog.listen('MDCDialog:opening', function() {
        dialog.root.querySelectorAll('.mdc-menu-surface').forEach(function(el) {
          el.classList.add("mdc-menu-surface--fixed");
        })
      });

      dialog.listen('MDCDialog:closed', function() {
        dialog.root.querySelectorAll('input').forEach(function(el) {
          el.blur();
        })
        dialog.root.remove();
      });

      dialog.open();
    }

    function preventDialogClosing(dialog) {
      dialog.escapeKeyAction= "";
      dialog.scrimClickAction= "";
      dialog.root.querySelectorAll(".mdc-dialog__close").forEach(function (btn) {
        btn.dataset.mdcDialogAction = '';
      });
    }
  }

  initFlatpickr() {
    flatpickr('.flatpickr', {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      time_24hr: true, 
      allowInput: true
    });

    flatpickr('.flatpickr_date', {
      dateFormat: "Y-m-d",
      allowInput: true
    });
      
    flatpickr('.flatpickr_time', {
      enableTime: true,
      noCalendar: true,
      enableSeconds: true,
      dateFormat: "H:i:S",
      time_24hr: true,
      allowInput: true
    });
  }

}