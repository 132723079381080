import { Controller } from "stimulus"
import $ from 'jquery';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "output", "draggableList"]

  initDraggableList(event){
    if (this.draggableListTarget.dataset.type == "steps") {
      let _this = this;

      $(this.draggableListTarget).sortable({
        update: function( event, ui ) {
          let ids = $(this).sortable('toArray');

          let _json = {new_order: ids, object_id: $(this).data("objectid"), type: $(this).data("objecttype")};
          
          fetch("/en/steps/reorder.js",
            {
              method: "post",
              body: JSON.stringify(_json),
              headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': Rails.csrfToken()
              },
              credentials: 'same-origin'
            }

          ).then(function (r) {location.reload();});

        }
      });
    }
  }

  submitSubscribeForm(event) {
    var email = $("input#subscriber_email").val();

    if (email.length > 0 && $("#subscribe-terms:checked").val() == 'true') {
      $.ajax({
        url: "https://auth.hundred.org/en/subscribe", 
        jsonp: "callback",
        dataType: "jsonp",
        data: {newsletter: true, subtype: "", email: email, service: "hundred", antibot: $("#subscribe-form .anti_field #name").val()},
        success: function( response ) {
          $("#subscribe-form").hide();
          $("#subscribe-thanks").removeClass("hidden")
        }
      });
    }
  }


} 
