import mdcAutoInit from '@material/auto-init';

import {MDCTextField} from '@material/textfield';
mdcAutoInit.register('MDCTextField', MDCTextField);

import {MDCTabBar} from '@material/tab-bar';
mdcAutoInit.register('MDCTabBar', MDCTabBar);

import {MDCChipSet} from '@material/chips';
mdcAutoInit.register('MDCChipSet', MDCChipSet);

import {MDCChip} from '@material/chips';
mdcAutoInit.register('MDCChip', MDCChip);

import {MDCSelect} from '@material/select';
mdcAutoInit.register('MDCSelect', MDCSelect);

import {MDCSwitch} from '@material/switch';
mdcAutoInit.register('MDCSwitch', MDCSwitch);

import {MDCCheckbox} from '@material/checkbox';
mdcAutoInit.register('MDCCheckbox', MDCCheckbox);
  
import {MDCList} from '@material/list';
mdcAutoInit.register('MDCList', MDCList);

import {MDCRipple} from '@material/ripple';
mdcAutoInit.register('MDCRipple', MDCRipple);

import {MDCFormField} from '@material/form-field';
mdcAutoInit.register('MDCFormField', MDCFormField);

import {MDCRadio} from '@material/radio';
mdcAutoInit.register('MDCRadio', MDCRadio);

import {MDCMenu} from '@material/menu';
mdcAutoInit.register('MDCMenu', MDCMenu);

import {MDCMenuSurface} from '@material/menu-surface';
mdcAutoInit.register('MDCMenuSurface', MDCMenuSurface);

import {MDCSlider} from '@material/slider';
mdcAutoInit.register('MDCSlider', MDCSlider);

import {MDCDialog} from '@material/dialog';
mdcAutoInit.register('MDCDialog', MDCDialog);


import {MDCSegmentedButton} from '@material/segmented-button';
import {MDCSegmentedButtonSegment} from '@material/segmented-button';
mdcAutoInit.register('MDCSegmentedButton', MDCSegmentedButton);

// import {MDCNotchedOutline} from '@material/notched-outline';
// mdcAutoInit.register('MDCNotchedOutline', MDCNotchedOutline);

import {MDCBanner} from '@material/banner';
mdcAutoInit.register('MDCBanner', MDCBanner);

import {MDCTooltip} from '@material/tooltip';
mdcAutoInit.register('MDCTooltip', MDCTooltip);

import {MDCSnackbar} from '@material/snackbar';
mdcAutoInit.register('MDCSnackbar', MDCSnackbar);

window.mdcAutoInit = function () {
  mdcAutoInit();
};

document.addEventListener("MDCAutoInit:End", () => {

  //BANNER
  const bannerElements = document.querySelectorAll('.mdc-banner');
  bannerElements.forEach((bannerEl) => {
    let banner = bannerEl.MDCBanner;
    if (!banner.isOpen)
      banner.open();
  });

  //SLIDER
  const sliderElements = document.querySelectorAll('.mdc-slider');
  sliderElements.forEach((sliderEl) => {
    let slider = sliderEl.MDCSlider;

    let input = sliderEl.querySelector("input");

    if (input != null) {
      if (input.value == "")
        slider.value = 0;
      else
        slider.value = input.value;
    }

    slider.listen('MDCSlider:change', () => {

      if (input != null) {
        input.value = slider.value;
        input.dispatchEvent(new Event('change'));
        
        if (input.closest('form') !== null)
          input.closest('form').dispatchEvent(new Event('change'));
      }

    });

  });


  //SEGMENTED Button
  const segmentedButtons = document.querySelectorAll('.mdc-segmented-button');
  segmentedButtons.forEach((segButtonEl) => {
    let segButton = segButtonEl.MDCSegmentedButton;

    //console.log("segButton", segButton)
    //segButton.selectSegment(1);

    let input = segButtonEl.querySelector("input");

    segButton.segments.forEach((seg) => {
      if (seg.root.dataset.value == input.value)
        seg.setSelected();
    });

    segButton.segments.forEach((segment) => {

      segment.listen('selected', () => {
        segment.setSelected();
        
        input.value = segment.root.dataset.value;
        input.dispatchEvent(new Event('change'));

        segButton.segments.forEach((seg) => {
          if (seg.index != segment.index)
            seg.setUnselected();
        });
      });
    });

  });


  //SELECT
  const selectElements = document.querySelectorAll('.mdc-select:not(.mdc-select-other)');  
  selectElements.forEach((selectEl) => {

    let menu = selectEl.querySelector('.mdc-select__menu')

    if (selectEl.getAttribute('listener4Input') !== 'true') {
      let select = selectEl.MDCSelect;
      let input = selectEl.querySelector('input');

      if (input !== null)
        select.value = input.value;
      
      let initSelectInput = function(e) {

        let einput = e.target.querySelector('input');

        if (input !== null) {
          input.value = select.value;
          input.dispatchEvent(new Event('change'));
          
          if (input.closest('form') !== null)
            input.closest('form').dispatchEvent(new Event('change'));
        }

      }

      select.listen('MDCSelect:change', initSelectInput);
      selectEl.setAttribute('listener4Input', 'true');
    }

  });

  //SELECT + OTHER
  const selectOtherElements = document.querySelectorAll('.select-other');  
  selectOtherElements.forEach((selectEl) => {

    if (selectEl.getAttribute('listener4Input') !== 'true') {
      let select = selectEl.querySelector('.mdc-select.mdc-select-other').MDCSelect;
      let input = selectEl.querySelector('input');

      if (input !== null)
        select.value = input.value;

      if (input.value.length > 0 && select.value == "")
        select.value = "Other";

      let initSelectInput = function(e) {
        if (e.target.querySelector('input') !== null)
          selectEl.querySelectorAll('input').forEach((el) => {el.value = select.value;})

        if (select.value.toLowerCase() == 'other') {
          let els = selectEl.querySelectorAll('input');

          els[els.length - 1].parentElement.style.display = 'block';
          els[els.length - 1].value = "";

          setTimeout(function() {els[els.length - 1].focus();}, 200);
          
        } else {
          let els = selectEl.querySelectorAll('input');

          els[els.length - 1].parentElement.style.display = 'none';
        }
      }

      select.listen('MDCSelect:change', initSelectInput);
      //console.log("listener4Input", "ADDED");
      selectEl.setAttribute('listener4Input', 'true');
    }

  });
});

window.addEventListener('DOMContentLoaded', (event) => {
  window.mdcAutoInit();
});