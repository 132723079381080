require("@rails/ujs").start()
require("@rails/activestorage").start()

require("packs/markerclusterer_compiled")
require("packs/carousel")
require("packs/autocomplete")
require("packs/logger")
require("packs/innovations")
require("trumbowyg")
require("packs/ammap")
require("packs/worldLow")
require("packs/usa2High")
require("packs/finlandLow")
require("packs/australiaHigh")
require("packs/map")
require("packs/material")
require("jquery-ui/ui/widgets/sortable")

import Rails from '@rails/ujs';

import $ from 'jquery'

import "trumbowyg"
import "flatpickr"

import "controllers"

import "../styles/media"

import logs from '../packs/logger'
import timeline from "../packs/plugins/timeline/timeline"

import {initMagnificPopup} from '../packs/initMagnificPopup'
import {initSlickCarousel} from '../packs/initSlickCarousel'

export {
  logs
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

$(document).ready( function($) {

  $(".session-card .show_program").on("click", function () {
    var program = $(this).closest(".session-card").find(".program");

    if (program.hasClass("active")) {
      program.removeClass("active");
    } else {
      program.addClass("active");
      $('html,body').animate({ scrollTop: program.offset().top - 100}, 500, function () {
      });
    }
  });

  $(".notification .mark").on("ajax:success", function (e) {
    $(this).parent().remove();
  });
      

  flatpickr('.flatpickr_date', {
    dateFormat: "Y-m-d"
  });

  initMagnificPopup();
  initSlickCarousel();

  $('.slow_button').click(function(e) {
    e.preventDefault();
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');

    if (target.length) {
      $('html,body').animate({ scrollTop: target.offset().top}, 200, function () {
        //Turbolinks.visit(e.target.href);
      });
    return false;
    }
  });

  if ($("#pagination_button").length > 0) {

    var scroll = function () {
      if (($("#pagination_button") !== undefined) && ($("#pagination_button").hasClass('autoload') == true) && ($("#pagination_button").hasClass("hidden") == false) && ($("#pagination_button").hasClass("loading") == false)) {
        var pos = $(window).scrollTop();  
        var pagination_pos = 0;
        var window_height = window.innerHeight;
        if (document.getElementById("pagination_button") != null) {
          pagination_pos = document.getElementById("pagination_button").offsetTop;
          if (pos > pagination_pos - window_height - 100) {
            $("#pagination_button").click();
          }   
        }
      }
    };

    var raf = window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        window.oRequestAnimationFrame;
    var $window = $(window);
    var lastScrollTop = $window.scrollTop();

    if (raf) {
      loop();
    }

    function loop() {
      var scrollTop = $window.scrollTop();
      if (lastScrollTop === scrollTop) {
        raf(loop);
        return;
      } else {
        lastScrollTop = scrollTop;

        scroll();
        raf(loop);
      }
    }

  }

});

//timeline(document.querySelectorAll('.timeline'));

$.magnificPopup.instance.close = function() {
  $(document).trigger('mfp-global-close');

  $.magnificPopup.proto.close.call();
};

function imageExists(url, callback) {
  var img = new Image();
  img.onload = function() { callback(true); };
  img.onerror = function() { callback(false); };
  img.src = url;
}

function toKb(num) {
  var result = num/1024;
  result = (Math.round(result * 100) / 100)
  return result;
}

function includes(container, value) {
  var returnValue = false;
  var pos = container.indexOf(value);
  if (pos >= 0) {
    returnValue = true;
  }
  return returnValue;
}

window.dispatchMapsEvent = function (...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
  console.log(">>>>>dispatchMapsEvent")
}